import { MetricsPanelCtrl } from 'app/plugins/sdk';

import _ from 'lodash';
import $ from 'jquery';

import {
	SeriesWrapper,
	SeriesWrapperSeries,
	SeriesWrapperTable,
	SeriesWrapperTableRow,
	SeriesTypeEnum
} from './SeriesWrapper';
import { EditorHelper } from './editor';

import { loadPlotly, loadIfNecessary } from './libLoader';
import { AnnoInfo } from './anno';
import { Axis } from 'plotly.js';

let Plotly: any; // Loaded dynamically!
const AXIS_FONT_SIZE = 14;
const AXIS_MAX_CHARS = 256;
const HOVER_MAX_CHARS = 20;

export interface Mapping {
	csv: boolean;
	metric: string;
	marker: boolean;
}

class PlotlyPanelCtrl extends MetricsPanelCtrl {
	static templateUrl = 'partials/module.html';
	static configVersion = 1; // An index to help config migration
	private timeSeries: SeriesWrapperSeries;

	dataRevision = 0;
	graphDiv: any;
	annotations = new AnnoInfo();
	series: SeriesWrapper[] = [];
	cfg: any;
	axis: Array<{ label: string, layout: { rangemode: string, showgrid: boolean, title: string, type: string, zeroline: boolean } }>

	// For editor
	editor: EditorHelper;
	initialized = false;

	public static readonly defaultTrace = {
		mapping: {},
		name: "",
		range: [[0, '#111'], [0.9, '#888'], [0.9, '#f00'], [1, '#f00']],
		show: {
			line: true,
			markers: true,
		},
		settings: {
			visible: true,
			orientation: 'v',
			line: {
				color: '#005f81',
				width: 6,
				dash: 'solid',
				shape: 'linear',
			},
			marker: {
				size: 15,
				symbol: 'circle',
				color: '#33B5E5',
				colorscale: 'YlOrRd',
				sizemode: 'diameter',
				sizemin: 3,
				sizeref: 0.2,
				line: {
					color: '#DDD',
					width: 0,
				},
				showscale: false,
			},
			color_option: 'ramp',
		},
	};

	static yaxis2: Partial<Axis> = {
		title: 'Annotations',
		type: 'linear',
		range: [0, 1],
		visible: false,
	};

	static defaults = {
		pconfig: {
			loadFromCDN: false,
			showAnnotations: true,
			fixScale: '',
			traces: [],
			settings: {
				type: 'scatter',
				displayModeBar: false,
			},
			layout: {
				showlegend: false,
				legend: {
					orientation: 'h',
				},
				dragmode: 'lasso', // (enumerated: "zoom" | "pan" | "select" | "lasso" | "orbit" | "turntable" )
				hovermode: 'closest',
				font: {
					family: '"Open Sans", Helvetica, Arial, sans-serif',
				},
				xaxis: {
					showgrid: true,
					zeroline: false,
					type: '',
					rangemode: 'normal', // (enumerated: "normal" | "tozero" | "nonnegative" )
				},
				yaxis: {
					showgrid: true,
					zeroline: false,
					type: '',
					rangemode: 'normal', // (enumerated: "normal" | "tozero" | "nonnegative" ),
				},
				zaxis: {
					showgrid: true,
					zeroline: false,
					type: '',
					rangemode: 'normal', // (enumerated: "normal" | "tozero" | "nonnegative" )
				},
			},
		},
	};

	constructor(
		$scope,
		$injector,
		private $rootScope,
		public uiSegmentSrv,
		private annotationsSrv
	) {
		super($scope, $injector);

		// defaults configs
		_.defaultsDeep(this.panel, PlotlyPanelCtrl.defaults);

		this.cfg = this.panel.pconfig;
		this.$scope.templateJSON = JSON.stringify(this.cfg.template, undefined, 2)
		this.$scope.dataWarnings = []
		const self = this
		loadPlotly(this.cfg).then(v => {
			Plotly = v;
			console.log('Plotly', v);

			// Wait till plotly exists has loaded before we handle any data
			self.events.on('render', self.onRender.bind(self));
			self.events.on('data-received', self.onDataReceived.bind(self));
			self.events.on('data-error', self.onDataError.bind(self));
			self.events.on('data-snapshot-load', self.onDataSnapshotLoad.bind(self));
		}).catch(e => { });

		// Standard handlers
		this.events.on('init-edit-mode', this.onInitEditMode.bind(this));
		this.events.on('panel-initialized', this.onPanelInitialized.bind(this));
	}

	getCssRule(selectorText): CSSStyleRule | null {
		const styleSheets = document.styleSheets;
		for (const styleSheet of styleSheets) {
			const rules = styleSheet.cssRules;
			for (const element of rules) {
				const rule = element as CSSStyleRule;
				if (rule.selectorText === selectorText)
					return rule;
			}
		}
		return null;
	}

	onDataError(err) {
		console.debug('Data error: ', err)
	}

	onInitEditMode() {
		console.debug('Init edit mode')
		this.editor = new EditorHelper(this);
		this.addEditorTab('Display', 'public/plugins/vroc-advanced-chart-panel/partials/tab_display.html', 2);
		this.addEditorTab('Traces', 'public/plugins/vroc-advanced-chart-panel/partials/tab_traces.html', 3);
		this.onTypeChange();
	}

	private _processConfigMigration() {
		console.debug('Migrating Plotly Configuration to version: ' + PlotlyPanelCtrl.configVersion);

		// Remove some things that should not be saved
		const cfg = this.panel.pconfig;
		delete cfg.layout.plot_bgcolor;
		delete cfg.layout.paper_bgcolor;
		delete cfg.layout.autosize;
		delete cfg.layout.height;
		delete cfg.layout.width;
		delete cfg.layout.margin;
		delete cfg.layout.scene;
		if (!this.is3d(cfg.settings.type))
			delete cfg.layout.zaxis;

		// Move from 'markers-lines' to checkbox
		if (cfg.settings.mode) {
			const old = cfg.settings.mode;
			const show = {
				markers: old.indexOf('markers') >= 0,
				lines: old.indexOf('lines') >= 0,
			};
			_.forEach(cfg.traces, trace => {
				trace.show = show;
			});
			delete cfg.settings.mode;
		}

		console.log('After Migration:', cfg);
		this.cfg = cfg;
		this.panel.version = PlotlyPanelCtrl.configVersion;
	}

	onPanelInitialized() {
		console.debug('Panel initialized')
		if (!this.panel.version || PlotlyPanelCtrl.configVersion > this.panel.version)
			this._processConfigMigration();
	}

	private _deepCopyWithTemplates = obj => {
		if (_.isArray(obj))
			return obj.map(val => this._deepCopyWithTemplates(val));
		else if (_.isString(obj))
			return this.templateSrv.replace(obj, this.panel.scopedVars);
		else if (_.isObject(obj)) {
			const copy = {};
			_.forEach(obj, (v, k) => {
				copy[k] = this._deepCopyWithTemplates(v);
			});
			return copy;
		}
		return obj;
	};

	private _truncateAxisLabel(label: string, dimensionPixels: number, fontSize: number, maxCharacters: number): string {
		const charCount = Math.ceil(dimensionPixels / (fontSize / 1.4));
		if (label.length > charCount || label.length > maxCharacters)
			return '..' + label.substring(label.length - Math.min(maxCharacters, charCount))
		else
			return label
	}

	private _getLayoutFromConfig() {
		// Copy from config
		let layout
		if (this.cfg.template)
			layout = this._deepCopyWithTemplates(this.cfg.template.layout);
		else
			layout = this._deepCopyWithTemplates(this.cfg.layout);
		layout.plot_bgcolor = 'transparent';
		layout.paper_bgcolor = layout.plot_bgcolor;
		layout.datarevision = this.dataRevision++;
		// Update the size
		const rect = this.graphDiv.getBoundingClientRect();
		layout.autosize = false; // height is from the div
		layout.height = this.height;
		layout.width = rect.width;

		// Make sure it is something
		if (!layout.xaxis)
			layout.xaxis = {};

		if (!layout.yaxis)
			layout.yaxis = {};

		// Fixed scales
		if (this.cfg.fixScale) {
			if ('x' === this.cfg.fixScale)
				layout.yaxis.scaleanchor = 'x';
			else if ('y' === this.cfg.fixScale)
				layout.xaxis.scaleanchor = 'y';
			else if ('z' === this.cfg.fixScale) {
				layout.xaxis.scaleanchor = 'z';
				layout.yaxis.scaleanchor = 'z';
			}
		}

		if (this.is3d(this.cfg.settings.type)) {
			if (!layout.zaxis)
				layout.zaxis = {};

			// 3d uses 'scene' for the axis
			layout.scene = {
				xaxis: layout.xaxis,
				yaxis: layout.yaxis,
				zaxis: layout.zaxis,
			};

			layout.margin = {
				l: 0,
				r: 0,
				t: 0,
				b: 5,
				pad: 0,
			};
		} else {
			delete layout.zaxis;
			delete layout.scene;

			const isDate = layout.xaxis.type === 'date';
			let b = 30;
			if (layout.xaxis.title)
				b = 65
			else if (isDate)
				b = 40

			layout.margin = {
				l: layout.yaxis.title ? 50 : 35,
				r: 5,
				t: 0,
				b,
				pad: 2,
			};

			// Set the range to the query window
			if (isDate && !layout.xaxis.range) {
				const range = this.timeSrv.timeRange();
				layout.xaxis.range = [range.from.valueOf(), range.to.valueOf()];
			}

			// get the css rule of grafana graph axis text
			const labelStyle = this.getCssRule('div.flot-text');
			if (labelStyle) {
				let color = labelStyle.style.color;
				if (!layout.font)
					layout.font = {};

				layout.font.color = color;

				// make the grid a little more transparent
				// @ts-ignore
				color = $.color
					.parse(color)
					.scale('a', 0.22)
					.toString();

				// set grid color (like grafana graph)
				layout.xaxis.gridcolor = color;
				layout.yaxis.gridcolor = color;
			}
		}
		if (this.cfg.settings.type === 'stack')
			layout.barmode = 'stack'

		if (!this.cfg.showAnnotations || this.is3d(this.cfg.settings.type))
			this.annotations.clear();

		layout.shapes = this.annotations.shapes;

		this._updateAxisLabels(layout);
		return layout;
	}

	private _updateChartAxis() {
		this.axis = []
		this.axis.push({ label: 'X Axis', layout: this.cfg.layout.xaxis })
		this.axis.push({ label: 'Y Axis', layout: this.cfg.layout.yaxis })
		if (this.is3d(this.cfg.settings.type))
			this.axis.push({ label: 'Z Axis', layout: this.cfg.layout.zaxis })
	}

	onUpdateTemplate() {
		this.$scope.$apply(() => {
			let template
			this.$scope.dataWarnings.length = 0;
			try {
				template = JSON.parse(this.$scope.templateJSON)
			} catch (e) {
				this.$scope.dataWarnings.push("JSON is invalid")
				return;
			}

			const out = Plotly.validateTemplate(this.graphDiv, template);
			if (out && out.length > 0) {
				this.$scope.dataWarnings.push(out.map(o => o.msg).join(','))
				return
			}

			this.cfg.template = template
			this.onConfigChanged()
		})
	}

	onSaveTemplate() {
		this.$scope.dataWarnings.length = 0;
		this.cfg.template = Plotly.makeTemplate(this.graphDiv)
		const out = Plotly.validateTemplate(this.graphDiv, this.cfg.template);
		if (out && out.length > 0) {
			this.$scope.dataWarnings.push(out.map(o => o.msg).join(','))
			//return
		}

		// Copy mapping to template
		this.cfg.template.mapping = []
		this.cfg.traces.map((templateConfig) => {
			const config = this._deepCopyWithTemplates(templateConfig);
			_.defaults(config, PlotlyPanelCtrl.defaultTrace);
			this.cfg.template.mapping.push(config.mapping);
		})

		this.$scope.templateJSON = JSON.stringify(this.cfg.template, undefined, 2)
		this.onConfigChanged()
	}

	onDeleteTemplate() {
		delete this.$scope.templateJSON
		delete this.cfg.template
		this.onConfigChanged()
	}

	private _getPlotlyConfig() {
		let plotlyConfig: any = {}
		const rect = this.graphDiv.getBoundingClientRect();
		const width = rect.width;
		const height = this.height;

		if (this.cfg.template) {
			plotlyConfig.template = this._deepCopyWithTemplates(this.cfg.template);
			plotlyConfig.template.layout.width = width;
			plotlyConfig.template.layout.height = height;
			delete plotlyConfig.template.mapping
		} else {
			plotlyConfig = this._getLayoutFromConfig();
			plotlyConfig.width = width;
			plotlyConfig.height = height;
		}
		return plotlyConfig
	}

	private _initializePlotly() {
		if (this.initialized)
			return

		console.debug('Init Plotly')
		const layout = this._getPlotlyConfig()
		const config = this._getConfig()
		const data = this._getTracesDataFromConfig()
		console.debug({ data, layout, config })
		Plotly.newPlot(this.graphDiv, data, layout, config)

		this.graphDiv.on('plotly_click', () => {
			if (data === undefined || data.points === undefined)
				return;

			for (const point of data.points) {
				let msg = `x: ${point.x.toPrecision(4)}, y: ${point.y.toPrecision(4)}`
				if (point.z)
					msg += `, z: ${point.zaxis}`
				let text = ''
				if (point.text)
					text = `${point.data.name}: ${point.text}`
				this.$rootScope.appEvent('alert-success', [
					text, msg
				]);
			}
		});

		this.graphDiv.on('plotly_selected', () => {
			if (data === undefined || data.points === undefined)
				return;

			if (data.points.length === 0) {
				console.debug('Nothing selected', data);
				return;
			}

			console.debug('User selected nodes: ', data);

			let min = Number.MAX_SAFE_INTEGER;
			let max = Number.MIN_SAFE_INTEGER;

			for (const point of data.points) {
				const ts = this.timeSeries.series.datapoints[point.pointIndex][1];
				min = Math.min(min, ts);
				max = Math.max(max, ts);
			}

			// At least 2 seconds
			min -= 1000;
			max += 1000;

			const range = { from: new Date(min - (min % 1000)).toISOString(), to: new Date(max - (max % 1000)).toISOString() };

			console.debug('Updating dashboard time: ', new Date(min), new Date(max));

			this.timeSrv.setTime(range);
		});

		this.initialized = true;
	}

	onRender() {
		console.debug('Rendering panel...')

		// ignore fetching data if another panel is in fullscreen
		if (this.otherPanelInFullscreenMode() || !this.graphDiv)
			return;

		if (!Plotly)
			return;

		if (!this.initialized)
			this._initializePlotly();
		else {
			console.debug('Redraw Plotly')
			const rect = this.graphDiv.getBoundingClientRect();
			this.graphDiv.layout.width = rect.width;
			this.graphDiv.layout.height = this.height;
			Plotly.redraw(this.graphDiv).then(() => {
				this.renderingCompleted();
			});
		}
		console.debug('Rendering complete')
	}

	private _getConfig() {
		const s = this.cfg.settings;

		return {
			showLink: false,
			displaylogo: false,
			showEditInChartStudio: true,
			showSendToCloud: true,
			plotlyServerURL: "https://chart-studio.plotly.com",
			displayModeBar: s.displayModeBar,
			modeBarButtonsToRemove: ['sendDataToCloud'], //, 'select2d', 'lasso2d']
		};
	}

	onDataSnapshotLoad(snapshot) {
		console.debug('Snapshot loaded')
		this.onDataReceived(snapshot);
	}

	onDataReceived(dataList) {
		if (!dataList || dataList.length === 0)
			return;

		console.debug('Data received, creating series...')
		const fInfo: SeriesWrapper[] = [];
		const refNames = new Map<string, string[]>()

		dataList.forEach((series, sidx) => {
			const refName = refNames.get(series.refId)
			if (refName)
				refName.push(series.target)
			else
				refNames.set(series.refId, [series.target])

			if (series.columns) {
				for (let i = 0; i < series.columns.length; i++)
					fInfo.push(new SeriesWrapperTable(series.refId, series, i));

				fInfo.push(new SeriesWrapperTableRow(series.refId, series));
			} else if (series.target) {
				fInfo.push(new SeriesWrapperSeries(series.refId, series, SeriesTypeEnum.value));
				this.timeSeries = new SeriesWrapperSeries(series.refId, series, SeriesTypeEnum.time);
				fInfo.push(this.timeSeries);
				fInfo.push(new SeriesWrapperSeries(series.refId, series, SeriesTypeEnum.relativetime));
				fInfo.push(new SeriesWrapperSeries(series.refId, series, SeriesTypeEnum.index));
			} else
				console.error('Unsupported Series response', sidx, series);
		});

		refNames.forEach((v, k) => {
			const values: Array<(number | undefined)> = []
			v.forEach(m => {
				const fieldInfo = fInfo.find(i => m === i.name)
				if (fieldInfo)
					values.push(fieldInfo.lastValue)
			})

			const t = { datapoints: v.map(x => [x.substr(0, x.lastIndexOf('(')), values]), target: k, refId: k }
			fInfo.push(new SeriesWrapperSeries(k, t, SeriesTypeEnum.text));
			fInfo.push(new SeriesWrapperSeries(k, { refId: k, target: k + '_latest', datapoints: values.map(val => [val, 0]) }, SeriesTypeEnum.value));
		})

		this.series = fInfo;
		console.debug('Series data updated: ', this.series)

		if (this.editor && !this.$scope.traceIndex) {
			this.editor.selectTrace(0);
		}

		if (this.initialized) {
			this.graphDiv.data = this._getTracesDataFromConfig();
			this.graphDiv.layout.datarevision = this.dataRevision++;
		} else
			this._initializePlotly();

		this._updateAxisLabels(this.graphDiv.layout);

		// Support Annotations
		if (this.cfg.showAnnotations && !this.is3d(this.cfg.settings.type)) {
			this.annotationsSrv
				.getAnnotations({
					dashboard: this.dashboard,
					panel: this.panel,
					range: this.range,
				})
				.then(results => {
					this.annotations.update(results);
					if (this.graphDiv.layout) {
						this.graphDiv.layout.shapes = this.annotations.shapes;
					}
					this.render();
				});
		} else
			this.render();
	}

	private _addMetricToTrace(trace: any, csvKeys: string | string[] | Mapping, path: string) {
		if (!path || !csvKeys)
			return

		let keys;
		let csv = false;

		if (typeof csvKeys === 'string')
			keys = csvKeys.split(',')
		else if ('metric' in csvKeys) {
			keys = csvKeys.metric.split(',')
			csv = csvKeys.csv
		} else
			keys = csvKeys;

		const data: any[] = []
		keys.forEach((key) => {
			const series = this.series.find(s => s.name === key)
			if (series)
				data.push(series.toArray())
		})

		if (typeof csvKeys === 'string' && data.length >= 1)
			_.set(trace, path, data[0])
		else if (data.length >= 1)
			_.set(trace, path, data[0])
		else if (csv)
			_.set(trace, path, [])
	}

	private _updateAxisLabels(layout) {
		// set axis labels based on metric
		const layoutConfig = (this.cfg.template) ? this.cfg.template.layout : this.cfg.layout
		const xAxisTitle = this.series.find(s => s.name === layoutConfig.xaxis.title)
		if (xAxisTitle) {
			const title = this._truncateAxisLabel(xAxisTitle.series.target,
				this.graphDiv.parentElement.clientWidth, AXIS_FONT_SIZE, AXIS_MAX_CHARS)
			layout.xaxis.title = { text: `${title}`, font: { size: AXIS_FONT_SIZE } } // can include {text, standoff, font: {color, family, size}}
		}
		const yAxisTitle = this.series.find(s => s.name === layoutConfig.yaxis.title)
		if (yAxisTitle) {
			const title = this._truncateAxisLabel(yAxisTitle.series.target,
				this.graphDiv.parentElement.clientHeight, AXIS_FONT_SIZE, AXIS_MAX_CHARS)
			layout.yaxis.title = { text: `${title}`, font: { size: AXIS_FONT_SIZE } }
		}
	}

	// This will update all trace settings *except* the data
	private _getTracesDataFromConfig() {
		if (this.cfg.template) {
			const traceConfig = this._deepCopyWithTemplates(this.cfg.template.data)
			let traceId = 0
			const data: any[] = []
			_.forEach(traceConfig, (t, chartType) => {
				t.forEach(trace => {
					const traceData = { type: chartType }
					const mapping = this.cfg.template.mapping[traceId]

					if (mapping.color !== undefined) {
						mapping['marker.color'] = mapping.color
						delete mapping.color
					}

					if (mapping.size !== undefined) {
						mapping['marker.size'] = mapping.size
						delete mapping.size
					}

					Object.keys(mapping).forEach(key => {
						this._addMetricToTrace(traceData, mapping[key], key);
					});

					data.push(traceData)
					traceId++
				});
			});

			return data
		}

		// Make sure we have a trace
		if (this.cfg.traces == null || this.cfg.traces.length < 1)
			return;

		let traces = this.cfg.traces.map((tconfig, idx) => {
			if (tconfig.mapping.color !== undefined) {
				tconfig.mapping['marker.color'] = tconfig.mapping.color
				delete tconfig.mapping.color
			}

			if (tconfig.mapping.size !== undefined) {
				tconfig.mapping['marker.size'] = tconfig.mapping.size
				delete tconfig.mapping.size
			}

			const config = this._deepCopyWithTemplates(tconfig);
			_.defaults(config, PlotlyPanelCtrl.defaultTrace);
			const mapping = config.mapping;

			const trace: any = {
				...config.settings,
				name: config.name || EditorHelper.createTraceName(idx),
				type: this.cfg.settings.type,
				mode: 'none', // really depends on config settings
			};

			let mode = '';
			if (config.show.markers) {
				mode += '+markers';

				delete trace.marker.sizemin;
				delete trace.marker.sizemode;
				delete trace.marker.sizeref;

				if (config.settings.color_option === 'ramp') {
					if (trace.marker.colorscale === 'Custom')
						trace.marker.colorscale = config.range
				} else {
					tconfig.mapping['marker.color'].metric = ''
					delete trace.marker.colorscale;
					delete trace.marker.showscale;
				}
			}

			if (config.show.lines)
				mode += '+lines';

			if (config.show.text)
				mode += '+text'

			if (this.cfg.settings.type === 'stack' || this.cfg.settings.type === 'bar') {
				trace.type = 'bar'
				if (config.show.lines)
					trace.marker.line = trace.line
			}

			Object.keys(mapping).forEach(key => {
				this._addMetricToTrace(trace, mapping[key], key);
			});

			let xTitle = this.cfg.layout.xaxis.title
			const xAxisTitle = this.series.find(s => s.name === this.cfg.layout.xaxis.title)
			if (xAxisTitle) {
				xTitle = xAxisTitle.series.nameAndPath || xAxisTitle.series.alias
				if (xTitle.length > HOVER_MAX_CHARS)
					xTitle = '..' + xTitle.substring(xTitle.length - HOVER_MAX_CHARS)
			}

			let yTitle = this.cfg.layout.yaxis.title
			const yAxisTitle = this.series.find(s => s.name === this.cfg.layout.yaxis.title)
			if (yAxisTitle) {
				yTitle = yAxisTitle.series.nameAndPath || yAxisTitle.series.alias
				if (yTitle.length > HOVER_MAX_CHARS)
					yTitle = '..' + yTitle.substring(yTitle.length - HOVER_MAX_CHARS)
			}

			trace.hovertemplate = ''
			if ((mapping.text && typeof mapping.text === 'string') || mapping.text.metric)
				trace.hovertemplate = `<em>%{text}</em><br>`

			if (trace.x) {
				trace.hovertemplate += `${xTitle}: `
				if (trace.x.length > 0 && typeof trace.x[0] === 'number')
					trace.hovertemplate += `%{x:.2f}`
				else
					trace.hovertemplate += `%{x}`
			}

			if (trace.y) {
				trace.hovertemplate += `<br>${yTitle}: `
				if (trace.y.length > 0 && typeof trace.y[0] === 'number')
					trace.hovertemplate += `%{y:.2f}`
				else
					trace.hovertemplate += `%{y}`
			}

			// Set the trace mode
			if (mode)
				trace.mode = mode.substring(1);

			return trace;
		});

		if (this.annotations.shapes.length > 0)
			traces = traces.concat(this.annotations.trace);

		return traces;
	}

	initTraceMapping(trace) {
		const mappings: Map<string, Mapping> = new Map([['marker.color', { csv: false, metric: '', marker: true }],
		['marker.size', { csv: false, metric: '', marker: true }], ['marker.symbol', { csv: false, metric: '', marker: true }],
		['text', { csv: false, metric: '', marker: true }]])
		const type = this.cfg.settings.type
		switch (type) {
			case 'heatmap':
				mappings.set('x', { csv: false, metric: '', marker: false })
				mappings.set('y', { csv: false, metric: '', marker: false })
				mappings.set('z', { csv: true, metric: '', marker: false })
			case 'surface':
				mappings.set('z', { csv: true, metric: '', marker: false })
				break;
			case 'pie':
				mappings.set('values', { csv: false, metric: '', marker: false })
				mappings.set('labels', { csv: false, metric: '', marker: false })
				break;
			case 'ohlc':
			case 'candlestick':
				mappings.set('x', { csv: false, metric: '', marker: false })
				mappings.set('close', { csv: false, metric: '', marker: false })
				mappings.set('open', { csv: false, metric: '', marker: false })
				mappings.set('low', { csv: false, metric: '', marker: false })
				mappings.set('high', { csv: false, metric: '', marker: false })
				break;
			default:
				mappings.set('x', { csv: type === 'contour', metric: '', marker: false })
				mappings.set('y', { csv: type === 'contour', metric: '', marker: false })
				if (this.is3d(this.cfg.settings.type))
					mappings.set('z', { csv: type === 'contour', metric: '', marker: false })
				break;
		}

		const newMap = {}
		mappings.forEach((value, key) => {
			if (trace.mapping[key]) mappings.set(key, trace.mapping[key])
			newMap[key] = mappings.get(key)
		});

		trace.mapping = newMap
	}

	onTypeChange() {
		this.cfg.traces.forEach((trace) => {
			this.initTraceMapping(trace)
		})

		this._updateChartAxis();
		this.onConfigChanged();
	}

	onConfigChanged() {
		this.$scope.dataWarnings = []
		console.debug('Config updating...')
		if (!Plotly)
			return;

		// Check if the plotly library changed
		loadIfNecessary(this.cfg).then(res => {
			// Updates the layout and redraw

			if (this.initialized) {
				const layout = this._getPlotlyConfig()
				const config = this._getConfig()
				const data = this._getTracesDataFromConfig();
				console.debug({ data, layout, config })
				Plotly.react(this.graphDiv, data, layout, config)
				console.debug('Config updated')
				this.refresh();
			} else
				this._initializePlotly();
		}).catch(e => { });
	}

	is3d(chartType) {
		return chartType === 'scatter3d' || chartType === 'contour' || chartType === 'surface';
	}

	link(scope, elem, attrs, ctrl) {
		this.graphDiv = elem.find('.plotly-spot')[0];
	}
}

export { PlotlyPanelCtrl, PlotlyPanelCtrl as PanelCtrl };
