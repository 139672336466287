import _ from 'lodash';

import { PlotlyPanelCtrl } from './module';

class AxisInfo {
	label: string;
	property: string; // mapping property to check in a trace
	segment: any; // The Grafana <metric-segment
	csv?: boolean; // does this axis support csv values
}

const REMOVE_KEY = '-- remove --';

export class EditorHelper {
	axis = new Array<AxisInfo>();
	trace: any; // Trace Config
	traces: any[]; // array of configs;

	symbol: any; // The Grafana <metric-segment for this symbol
	mapping: any = {}; // The Grafana <metric-segment for this symbol

	constructor(public ctrl: PlotlyPanelCtrl) {
		this.ctrl.$scope.symbolSeg = {}
		if (!this.ctrl.cfg.traces || this.ctrl.cfg.traces.length < 1)
			this.createTrace()
	}

	onConfigChanged() {
		this.ctrl.onConfigChanged();
	}

	//-----------------------------------------------------------------------
	// Manage Traces
	//-----------------------------------------------------------------------

	selectTrace(index: number) {
		this.ctrl.$scope.traces = this.ctrl.cfg.traces;

		if (index >= this.ctrl.cfg.traces.length)
			index = this.ctrl.cfg.traces.length - 1;

		this.ctrl.$scope.traceIndex = index;
		this.ctrl.$scope.trace = this.ctrl.cfg.traces[index];
		if (!this.ctrl.$scope.symbolSeg[index])
			this.ctrl.$scope.symbolSeg[index] = this.ctrl.uiSegmentSrv.newSegment(this.ctrl.$scope.trace.settings.marker.symbol);

		_.defaults(this.ctrl.$scope.trace, PlotlyPanelCtrl.defaultTrace);
		if (!this.ctrl.$scope.trace.name)
			this.ctrl.$scope.trace.name = EditorHelper.createTraceName(index);

		// Now set one for each key
		this.mapping = {};
		this.axis = []
		_.forEach(this.ctrl.$scope.trace.mapping, (value, key) => {
			this.updateSegMapping(value, key);
		});
	}

	private updateSegMapping(value, key) {
		if (value && value !== REMOVE_KEY) {
			let metric
			let csv
			let marker = (key === 'text' || key === 'marker.color' || key === 'marker.size' || key === 'marker.symbol')
			let fake = false
			if (typeof value === 'object' && 'metric' in value) {
				csv = value.csv
				if (value.metric)
					metric = value.metric
				else {
					metric = 'Select Metric'
					fake = true
				}
				marker = value.marker
			} else {
				csv = false
				metric = value
			}
			const series = this.ctrl.series.find(s => s.name === metric);
			const opts: any = {
				value: metric,
				text: metric,
				series,
				custom: csv,
				fake,
				selectMode: true,
				expandable: false
			};

			if (!marker && !series)
				opts.html = metric + ' <i class="fa fa-exclamation-triangle"></i>';

			this.mapping[key] = this.ctrl.uiSegmentSrv.newSegment(opts);
			if (!marker) {
				this.axis.push({
					label: key,
					property: key,
					csv,
					segment: this.mapping[key]
				});
			}
		} else {
			this.mapping[key] = this.ctrl.uiSegmentSrv.newSegment({
				value: 'Select Metric',
				fake: true,
				selectMode: true
			});
		}
	}

	onAddColorSegment() {
		this.ctrl.$scope.trace.range.push([0, '#fff'])
		this.ctrl.onConfigChanged()
	}

	onDeleteColorSegment(itemIndex) {
		this.ctrl.$scope.trace.range.splice(itemIndex, 1)
		this.ctrl.onConfigChanged()
	}

	createTrace() {
		const trace = _.cloneDeep(PlotlyPanelCtrl.defaultTrace);
		trace.name = EditorHelper.createTraceName(this.ctrl.cfg.traces.length);
		this.ctrl.initTraceMapping(trace)
		this.ctrl.cfg.traces.push(trace);
		this.selectTrace(this.ctrl.cfg.traces.length - 1);
		this.ctrl.onConfigChanged();
	}

	removeCurrentTrace() {
		if (this.ctrl.$scope.traces.length <= 1) {
			console.error('Wont remove a single trace', this);
			return;
		}

		for (let i = 0; i < this.ctrl.$scope.traces.length; i++) {
			if (this.ctrl.$scope.trace === this.ctrl.$scope.traces[i]) {
				this.ctrl.$scope.traces.splice(i, 1);
				if (i >= this.ctrl.$scope.traces.length) {
					i = this.ctrl.$scope.traces.length - 1;
				}
				this.ctrl.onConfigChanged();
				this.selectTrace(i);
				return;
			}
		}

		console.error('Could not find', this);
	}

	static createTraceName(idx: number) {
		return 'Trace ' + (idx + 1);
	}

	//-----------------------------------------------------------------------
	// SERIES
	//-----------------------------------------------------------------------

	getSeriesSegs(withRemove = false): Promise<any[]> {
		return new Promise((resolve, reject) => {
			const series: any[] = [];

			if (withRemove) {
				series.push(
					this.ctrl.uiSegmentSrv.newSegment({
						fake: true,
						value: REMOVE_KEY,
						series: null,
						selectMode: true
					})
				);
			} else {
				series.push(this.ctrl.uiSegmentSrv.newSegment({
					value: 'Select Metric',
					fake: true,
					selectMode: true
				}));
			}

			this.ctrl.series.forEach(s => {
				series.push(
					this.ctrl.uiSegmentSrv.newSegment({
						value: s.name,
						series: s,
						selectMode: true
					})
				);
			});

			resolve(series);
		});
	}

	private _setAxis(axis: AxisInfo) {
		let v = axis.segment.value
		if (v === REMOVE_KEY) v = ''
		if (this.ctrl.$scope.trace.mapping[axis.property].metric !== undefined)
			this.ctrl.$scope.trace.mapping[axis.property].metric = v
		else
			this.ctrl.$scope.trace.mapping[axis.property] = v
		console.debug('Set metric mapping: ', axis.property, v, this.ctrl.$scope.trace.mapping)
		this.onConfigChanged();
	}

	onAxisSeriesChanged(axis: AxisInfo) {
		this._setAxis(axis)
	}

	getMetric(path: string) {
		if (!this.mapping[path])
			this.mapping[path] = [this.ctrl.uiSegmentSrv.newSegment({
										value: 'Select Metric',
										fake: true,
										selectMode: true
									})]

		return [this.mapping[path]]
	}

	onMetricChange(path: string) {
		this._setAxis({ label: path, property: path, segment: this.mapping[path] })
	}

	//-----------------------------------------------------------------------
	// SYMBOLS
	//-----------------------------------------------------------------------

	onSymbolChanged() {
		this.ctrl.$scope.trace.settings.marker.symbol = this.ctrl.$scope.symbolSeg[this.ctrl.$scope.traceIndex].value;
		this.onConfigChanged();
	}

	getSymbolSegs(): Promise<any[]> {
		return new Promise((resolve, reject) => {
			const txt = [
				'circle',
				'circle-open',
				'circle-dot',
				'circle-open-dot',
				'square',
				'square-open',
				'square-dot',
				'square-open-dot',
				'diamond',
				'diamond-open',
				'diamond-dot',
				'diamond-open-dot',
				'cross',
				'cross-open',
				'cross-dot',
				'cross-open-dot',
				'x',
				'x-open',
				'x-dot',
				'x-open-dot',
				'triangle-up',
				'triangle-up-open',
				'triangle-up-dot',
				'triangle-up-open-dot',
				'triangle-down',
				'triangle-down-open',
				'triangle-down-dot',
				'triangle-down-open-dot',
				'triangle-left',
				'triangle-left-open',
				'triangle-left-dot',
				'triangle-left-open-dot',
				'triangle-right',
				'triangle-right-open',
				'triangle-right-dot',
				'triangle-right-open-dot',
				'triangle-ne',
				'triangle-ne-open',
				'triangle-ne-dot',
				'triangle-ne-open-dot',
				'triangle-se',
				'triangle-se-open',
				'triangle-se-dot',
				'triangle-se-open-dot',
				'triangle-sw',
				'triangle-sw-open',
				'triangle-sw-dot',
				'triangle-sw-open-dot',
				'triangle-nw',
				'triangle-nw-open',
				'triangle-nw-dot',
				'triangle-nw-open-dot',
				'pentagon',
				'pentagon-open',
				'pentagon-dot',
				'pentagon-open-dot',
				'hexagon',
				'hexagon-open',
				'hexagon-dot',
				'hexagon-open-dot',
				'hexagon2',
				'hexagon2-open',
				'hexagon2-dot',
				'hexagon2-open-dot',
				'octagon',
				'octagon-open',
				'octagon-dot',
				'octagon-open-dot',
				'star',
				'star-open',
				'star-dot',
				'star-open-dot',
				'hexagram',
				'hexagram-open',
				'hexagram-dot',
				'hexagram-open-dot',
				'star-triangle-up',
				'star-triangle-up-open',
				'star-triangle-up-dot',
				'star-triangle-up-open-dot',
				'star-triangle-down',
				'star-triangle-down-open',
				'star-triangle-down-dot',
				'star-triangle-down-open-dot',
				'star-square',
				'star-square-open',
				'star-square-dot',
				'star-square-open-dot',
				'star-diamond',
				'star-diamond-open',
				'star-diamond-dot',
				'star-diamond-open-dot',
				'diamond-tall',
				'diamond-tall-open',
				'diamond-tall-dot',
				'diamond-tall-open-dot',
				'diamond-wide',
				'diamond-wide-open',
				'diamond-wide-dot',
				'diamond-wide-open-dot',
				'hourglass',
				'hourglass-open',
				'bowtie',
				'bowtie-open',
				'circle-cross',
				'circle-cross-open',
				'circle-x',
				'circle-x-open',
				'square-cross',
				'square-cross-open',
				'square-x',
				'square-x-open',
				'diamond-cross',
				'diamond-cross-open',
				'diamond-x',
				'diamond-x-open',
				'cross-thin',
				'cross-thin-open',
				'x-thin',
				'x-thin-open',
				'asterisk',
				'asterisk-open',
				'hash',
				'hash-open',
				'hash-dot',
				'hash-open-dot',
				'y-up',
				'y-up-open',
				'y-down',
				'y-down-open',
				'y-left',
				'y-left-open',
				'y-right',
				'y-right-open',
				'line-ew',
				'line-ew-open',
				'line-ns',
				'line-ns-open',
				'line-ne',
				'line-ne-open',
				'line-nw',
				'line-nw-open',
			];

			const segs: any[] = [];

			_.forEach(txt, val => {
				segs.push(this.ctrl.uiSegmentSrv.newSegment(val));
			});

			resolve(segs);
		});
	}
}
