import _ from 'lodash';
import moment from 'moment';

// This gives a standard way to get a value for a given field
export abstract class SeriesWrapper {
	refId: string // From Query Target
	name: string
	series: any

	min?: number
	max?: number
	sum?: number
	count?: number
	firstValue?: number
	lastValue?: number


	/** @ngInject */
	constructor(refId: string) {
		this.refId = refId
	}

	// The best key for this field
	getKey(): string {
		return this.name
	}

	abstract toArray(): Array<string | number | boolean>;
}

export enum SeriesTypeEnum {
	value,
	index,
	time,
	relativetime,
	text
}

export class SeriesWrapperSeries extends SeriesWrapper {
	constructor(refId: string, public series: any, public seriesType: SeriesTypeEnum) {
		super(refId)
		this.count = series.datapoints.length
		this.name = series.target

		switch (seriesType) {
			case SeriesTypeEnum.index:
				this.name += '@index'
				break;
			case SeriesTypeEnum.time:
				this.name += '@time'
				break;
			case SeriesTypeEnum.relativetime:
				this.name += '@rel-time'
				break;
			case SeriesTypeEnum.text:
				this.name += '@text'
				break;
			default:
				break;
		}

		if (this.series.datapoints.length === 0)
			this.lastValue = 0

		this.series.datapoints.forEach((ts, index) => {
			let v
			if (SeriesTypeEnum.index === this.seriesType)
				v = index
			else if (SeriesTypeEnum.time === this.seriesType)
				v = moment(new Date(ts[1])).format("YYYY-MM-DD HH:MM:SS")
			else if (SeriesTypeEnum.relativetime === this.seriesType)
				v = ts[1]
			else if (SeriesTypeEnum.text === this.seriesType)
				return
			else
				v = ts[0]

			if (!this.firstValue)
				this.firstValue = v

			this.lastValue = v

			if (!this.min || v < this.min)
				this.min = v
			if (!this.max || v > this.max)
				this.max = v
			if (!this.sum)
				this.sum = v
			else
				this.sum += v
		});
	}

	toArray(): any[] {
		return this.series.datapoints.map((ts, index) => {
			if (SeriesTypeEnum.index === this.seriesType)
				return index
			else if (SeriesTypeEnum.time === this.seriesType)
				return moment(new Date(ts[1])).format("YYYY-MM-DD HH:MM:SS")
			else if (SeriesTypeEnum.relativetime === this.seriesType) {
				if (this.max && this.min)
					return (ts[1] - this.min)
				else
					return ts[1]
			} else
				return ts[0]
		});
	}
}

export class SeriesWrapperTableRow extends SeriesWrapper {
	constructor(refId: string, public table: any) {
		super(refId);

		this.name = refId + '@row'
		this.count = this.table.rows.length
	}

	toArray(): any[] {
		const count = this.table.rows.length
		const arr = new Array(count)
		for (let i = 0; i < count; i++)
			arr[i] = i

		return arr
	}
}

export class SeriesWrapperTable extends SeriesWrapper {
	constructor(refId: string, public table: any, public index: number) {
		super(refId)
		this.count = table.rows.length

		const col = table.columns[index]
		if (!col)
			throw new Error('Unknown Column: ' + index)

		this.name = col.text
	}

	toArray(): any[] {
		return _.map(this.table.rows, row => row[this.index])
	}
}
